import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {Place} from '@shared/api/app_api';

import {UnthemedButton} from '@shared-web/components/core/button';
import {LoadingIndicator} from '@shared-web/components/core/loading_indicator';
import {Spacing} from '@shared-web/components/core/spacing';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {closeIcon} from '@shared-web/components/icons/close_icon';
import {mapsIcon} from '@shared-web/components/icons/maps_icon';
import {notifyError} from '@shared-web/lib/notification';
import {useTheme} from '@shared-web/theme/theme_context';

import {ChatGptMarkdown} from '@src/components/chat_gpt_markdown';
import {placePhotoBackground} from '@src/components/place_utils';
import {apiCall} from '@src/lib/api';
import {generatePOIPrompt} from '@src/lib/chat_gpt_prompts';

interface PlaceDetailsProps {
  place: Place;
  onClose?: () => void;
}

const DEFAULT_WIDTH = 256;

export const PlaceDetails: FC<PlaceDetailsProps> = ({place, onClose}) => {
  const {
    main: {textColor},
  } = useTheme();
  const {
    address,
    photos,
    displayName,
    primaryTypeDisplayName,
    rating,
    userRatingCount,
    googleMapsUri,
  } = place;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const width = useMemo(
    () => wrapperRef.current?.getBoundingClientRect().width ?? DEFAULT_WIDTH,
    []
  );

  const [chatGpt, setChatGpt] = useState<string>();
  const loadChatGpt = useCallback(() => {
    const {city, country} = address;
    const prompt = generatePOIPrompt({
      city,
      country,
      address: address.address,
      poi: displayName,
    });
    apiCall('POST /chat-gpt', {prompt})
      .then(({res}) => {
        setChatGpt(res);
      })
      .catch((err: unknown) => notifyError(err));
  }, [address, displayName]);
  useEffect(() => loadChatGpt(), [loadChatGpt]);

  return (
    <Wrapper ref={wrapperRef}>
      <CloseIcon onClick={onClose}>
        <SvgIcon icon={closeIcon} size={24} color="#ffffff" />
      </CloseIcon>
      <Image style={placePhotoBackground(photos[0], width)} />
      <Content>
        <Title>{displayName}</Title>
        <SubTitle>
          <div>{`${primaryTypeDisplayName} - ${address.address}`}</div>
          <MapsIcon href={googleMapsUri} target="_blank" rel="noreferrer">
            <div>{`${rating}★ (${userRatingCount.toLocaleString()})`}</div>
            <SvgIcon icon={mapsIcon} size={16} color={'#888'} />
          </MapsIcon>
        </SubTitle>
        {/* <Button onClickAsync={handleChatGptClick}>CHAT GPT</Button> */}
        <Spacing height={16} />
        <ChatGpt>
          {chatGpt === undefined ? (
            <Loading>
              <LoadingIndicator color={textColor} />
              <div>Chargement...</div>
            </Loading>
          ) : (
            <ChatGptMarkdown message={chatGpt} />
          )}
        </ChatGpt>
      </Content>
    </Wrapper>
  );
};

PlaceDetails.displayName = 'PlaceDetails';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 16px env(safe-area-inset-bottom) 16px;
`;

const ChatGpt = styled.div`
  position: relative;
  flex-grow: 1;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

const CloseIcon = styled(UnthemedButton)`
  display: flex;
  position: fixed;
  top: env(safe-area-inset-top);
  right: 0;
  padding: 16px;
  background: none;
  opacity: 0.8;
  svg {
    stroke: #0000008a;
    stroke-width: 4px;
    overflow: visible;
  }
`;

const MapsIcon = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
`;

const Image = styled.div`
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-position: center center;
  background-size: cover;
  background-color: #ccc;
`;

const Title = styled.div`
  font-size: 24px;
`;

const SubTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  font-size: 16px;
  color: #888;
`;
