import {GeocodingAddress, Place} from '@shared/api/app_api';

import {createMapStore} from '@shared-web/lib/map_data_store';
import {notifyError} from '@shared-web/lib/notification';

import {apiCall} from '@src/lib/api';

const placeDetailsStore = createMapStore<string | undefined, Place>({
  persist: {key: 'place_details'},
});
export const getPlaceDetails = placeDetailsStore.getData;
export const usePlaceDetails = placeDetailsStore.useData;
export const setPlaceDetails = placeDetailsStore.setData;

export function fetchPlaceDetails(placeId?: string): void {
  if (placeId === undefined) {
    return;
  }
  if (getPlaceDetails(placeId)) {
    return;
  }
  apiCall('POST /place-details', {id: placeId})
    .then(place => setPlaceDetails(place.id, place))
    .catch(notifyError);
}

const geocodingAddressStore = createMapStore<string | undefined, GeocodingAddress>({
  persist: {key: 'geocoding_address'},
});
export const getGeocodingAddress = geocodingAddressStore.getData;
export const useGeocodingAddress = geocodingAddressStore.useData;
export const setGeocodingAddress = geocodingAddressStore.setData;

export function fetchGeocodingAddress(loc: {lat: number; lng: number}): void {
  const {lat, lng} = loc;
  const key = `${lat};${lng}`;
  if (getGeocodingAddress(key)) {
    return;
  }
  apiCall('POST /reverse-geocoding', {lat, lng})
    .then(({loc}) => {
      if (loc === undefined) {
        notifyError(`Échec de la récupération de l'adresse`);
      } else {
        setGeocodingAddress(key, loc);
      }
    })
    .catch((err: unknown) => notifyError(err));
}

const chatGptStore = createMapStore<string | undefined, string>({
  persist: {key: 'chat_gpt'},
});
export const getChatGpt = chatGptStore.getData;
export const useChatGpt = chatGptStore.useData;
export const setChatGpt = chatGptStore.setData;

export function fetchChatGpt(prompt?: string): void {
  if (prompt === undefined) {
    return;
  }
  if (getChatGpt(prompt) !== undefined) {
    return;
  }
  apiCall('POST /chat-gpt', {prompt})
    .then(({res}) => {
      setChatGpt(prompt, res);
    })
    .catch((err: unknown) => notifyError(err));
}
