export function generatePOIPrompt(opts: {
  city: string;
  country: string;
  address: string;
  poi: string;
}): string {
  const {city, country, address, poi} = opts;
  return [
    `Je suis un touriste en balade qui visite la ville "${city}" dans le pays "${country}"`,
    `J'arrive au point d'intérêt "${poi}" situé à l'adresse "${address}".`,
    `Que pourrait me raconter un guide touristique à propos de cet endroit ?`,
    `Quels sont les faits amusants et informations interessantes à propos de cet endroit ?`,
    `Ne mentionne pas et ne donne pas l'impression que tu es un guide touristique !`,
  ].join(' ');
}

export enum ItineraryRadius {
  Quartier = 'quartier',
  Ville = 'ville',
  Pays = 'pays',
}

export function generateItineraryPrompt(opts: {
  country: string;
  city: string;
  address: string;
  radius: ItineraryRadius;
}): string {
  const {country, city, address, radius} = opts;
  const positionPrompt = {
    [ItineraryRadius.Quartier]: 'quel quartier',
    [ItineraryRadius.Ville]: 'quelle ville',
    [ItineraryRadius.Pays]: 'quel pays',
  }[radius];
  const radiusPrompt = {
    [ItineraryRadius.Quartier]: 'ce quartier',
    [ItineraryRadius.Ville]: 'cette ville',
    [ItineraryRadius.Pays]: 'ce pays',
  }[radius];
  return [
    `Je suis à l'adresse "${address}" dans la ville "${city}" dans le pays "${country}".`,
    `Dans ${positionPrompt} est-ce que je me trouve ? Quels sont les endroits intéressants à visiter dans ${radiusPrompt} ?`,
    `Propose moi également un itinéraire.`,
  ].join(' ');
}
