import {FC, useEffect} from 'react';
import {styled} from 'styled-components';

import {PlaceDetails} from '@src/components/place_details';
import {fetchPlaceDetails, usePlaceDetails} from '@src/lib/stores';

interface PlaceLoaderProps {
  placeId: string;
  onClose?: () => void;
}

export const PlaceLoader: FC<PlaceLoaderProps> = props => {
  const {placeId, onClose} = props;
  const place = usePlaceDetails(placeId);
  useEffect(() => fetchPlaceDetails(placeId), [placeId]);
  return place ? <PlaceDetails place={place} onClose={onClose} /> : <Wrapper>Loading...</Wrapper>;
};

PlaceLoader.displayName = 'PlaceLoader';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
