import {Arr, Num, Obj, Opt, SchemaToType, Str} from '@shared/api/core/api_schema';

const PlaceSchema = Obj({
  location: Obj({latitude: Num(), longitude: Num()}),
  address: Obj({
    country: Str(),
    city: Str(),
    address: Str(),
  }),
  id: Str(),
  displayName: Str(),
  primaryType: Str(),
  primaryTypeDisplayName: Str(),
  types: Arr(Str()),
  googleMapsUri: Str(),
  rating: Num(),
  userRatingCount: Num(),
  photos: Arr(
    Obj({
      name: Str(),
      width: Num(),
      height: Num(),
    })
  ),
  icon: Str(),
  color: Str(),
});

export type Place = SchemaToType<typeof PlaceSchema>;

const GeocodingAddressSchema = Obj({country: Str(), city: Str(), address: Str()});
export type GeocodingAddress = SchemaToType<typeof GeocodingAddressSchema>;

const PlaceItemSchema = Obj({
  label: Str(),
  mainText: Str(),
  secondaryText: Str(),
  placeId: Str(),
});
export type PlaceItem = SchemaToType<typeof PlaceItemSchema>;

export const APP_API = {
  '/search-places': {
    POST: {
      req: Obj({lat: Num(), lng: Num(), radius: Num()}),
      res: Obj({
        places: Arr(PlaceSchema),
      }),
    },
  },
  '/place-details': {
    POST: {
      req: Obj({id: Str()}),
      res: PlaceSchema,
    },
  },
  '/place-autocomplete': {
    POST: {
      req: Obj({query: Str(), locationBias: Obj({lat: Num(), lng: Num(), radius: Num()})}),
      res: Obj({res: Arr(PlaceItemSchema)}),
    },
  },
  '/reverse-geocoding': {
    POST: {
      req: Obj({lat: Num(), lng: Num()}),
      res: Obj({loc: Opt(GeocodingAddressSchema)}),
    },
  },
  '/maps-icon': {
    GET: {
      req: Obj({url: Str(), color: Str()}),
      raw: true,
    },
  },
  '/chat-gpt': {
    POST: {
      req: Obj({prompt: Str()}),
      res: Obj({res: Str()}),
    },
  },
};
