import {sanitize} from 'dompurify';
import {marked} from 'marked';
import {FC, useMemo} from 'react';
import {styled} from 'styled-components';

interface ChatGptMarkdownProps {
  message?: string;
}

export const ChatGptMarkdown: FC<ChatGptMarkdownProps> = ({message}) => {
  const html = useMemo(() => {
    return message === undefined ? '' : sanitize(marked(message, {async: false}));
  }, [message]);
  return <Wrapper dangerouslySetInnerHTML={{__html: html}} />;
};

ChatGptMarkdown.displayName = 'ChatGptMarkdown';

const Wrapper = styled.div`
  pre {
    margin-top: 0.5rem;
  }

  h1 {
    font-weight: 700;
    letter-spacing: -0.04rem;
  }

  h1:first-child {
    margin-top: 0;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  h2:first-child {
    margin-top: 0;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  h3:first-child {
    margin-top: 0;
  }

  h4 {
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  h4:first-child {
    margin-top: 0;
  }

  h5 {
    font-weight: 600;
  }

  h5:first-child {
    margin-top: 0;
  }

  blockquote {
    --tw-border-opacity: 1;
    border-color: rgb(155 155 155 / var(--tw-border-opacity));
    line-height: 1.5rem;
    margin: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  blockquote {
    border-left-width: 2px;
    padding-left: 1rem;
  }

  blockquote > p {
    margin: 0;
  }

  blockquote > p:after,
  blockquote > p:before {
    display: none;
  }

  table {
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-collapse: separate;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    width: 100%;
  }

  p {
    margin-bottom: 0.5rem;
  }

  p + :where(ol, ul) {
    margin-top: 0;
  }

  :where(ol, ul) > li > :last-child {
    margin-bottom: 0;
  }

  :where(ol, ul) > li > :first-child {
    margin-bottom: 0;
    margin-top: 0;
  }

  th {
    background-color: var(--border-light);
    border-bottom-width: 1px;
    border-color: var(--border-medium);
    border-top-width: 1px;
    padding: 0.25rem 0.75rem;
  }

  th {
    border-left-width: 1px;
  }

  th:first-child {
    border-top-left-radius: 0.375rem;
    padding-left: 0.75rem;
  }

  th:last-child {
    border-right-width: 1px;
    border-top-right-radius: 0.375rem;
    padding-right: 0.75rem;
  }

  td {
    border-bottom-width: 1px;
    border-color: var(--border-medium);
    padding: 0.25rem 0.75rem;
  }

  td {
    border-left-width: 1px;
  }

  td:first-child {
    padding-left: 0.75rem;
  }

  td:last-child {
    border-right-width: 1px;
    padding-right: 0.75rem;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 0.375rem;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.375rem;
  }

  a {
    color: var(--link);
    font-weight: 400;
    text-decoration-line: none;
  }

  .float-image + p {
    margin-top: 0;
  }

  //
  //
  //

  a {
    color: #2424a3;
    font-weight: 500;
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }

  strong {
    font-weight: 600;
  }

  a strong {
    color: inherit;
  }

  blockquote strong {
    color: inherit;
  }

  thead th strong {
    color: inherit;
  }

  ol {
    list-style-type: decimal;
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }

  ol {
    padding-left: 1.625em;
  }

  ol[type='A'] {
    list-style-type: upper-alpha;
  }

  ol[type='a'] {
    list-style-type: lower-alpha;
  }

  ol[type='A s'] {
    list-style-type: upper-alpha;
  }

  ol[type='a s'] {
    list-style-type: lower-alpha;
  }

  ol[type='I'] {
    list-style-type: upper-roman;
  }

  ol[type='i'] {
    list-style-type: lower-roman;
  }

  ol[type='I s'] {
    list-style-type: upper-roman;
  }

  ol[type='i s'] {
    list-style-type: lower-roman;
  }

  ol[type='1'] {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }

  ul {
    padding-left: 1.625em;
  }

  ol > li::marker {
    font-weight: 400;
  }

  hr {
    border-color: #00000033;
    border-top-width: 1px;
    margin-bottom: 3em;
    margin-top: 3em;
  }

  blockquote {
    font-style: italic;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 1.6em;
    margin-top: 1.6em;
  }

  blockquote {
    border-left-color: #00000033;
    border-left-width: 0.25rem;
    padding-left: 1em;
  }

  blockquote p:first-of-type:before {
    content: open-quote;
  }

  blockquote p:last-of-type:after {
    content: close-quote;
  }

  h1 {
    color: var(--tw-prose-headings);
    font-size: 2.25em;
    font-weight: 800;
    line-height: 1.1111111;
    margin-bottom: 0.8888889em;
    margin-top: 0;
  }

  h1 strong {
    color: inherit;
    font-weight: 900;
  }

  h2 {
    color: var(--tw-prose-headings);
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.3333333;
    margin-bottom: 1em;
    margin-top: 2em;
  }

  h2 strong {
    color: inherit;
    font-weight: 800;
  }

  h3 {
    color: var(--tw-prose-headings);
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 0.6em;
    margin-top: 1.6em;
  }

  h3 strong {
    color: inherit;
    font-weight: 700;
  }

  h4 {
    color: var(--tw-prose-headings);
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
  }

  h4 strong {
    color: inherit;
    font-weight: 700;
  }

  img {
    margin-bottom: 2em;
    margin-top: 2em;
  }

  figure > * {
    margin-bottom: 0;
    margin-top: 0;
  }

  figcaption {
    color: var(--tw-prose-captions);
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
  }

  code {
    background-color: var(--gray-100);
    border-radius: 0.25rem;
    color: var(--tw-prose-code);
    font-size: 0.875em;
    font-weight: 500;
    padding: 0.15rem 0.3rem;
  }

  code:before {
    content: none;
  }

  code:after {
    content: none;
  }

  a code {
    color: inherit;
  }

  h1 code {
    color: inherit;
  }

  h2 code {
    color: inherit;
    font-size: 0.875em;
  }

  h3 code {
    color: inherit;
    font-size: 0.9em;
  }

  h4 code {
    color: inherit;
  }

  blockquote code {
    color: inherit;
  }

  thead th code {
    color: inherit;
  }

  pre {
    background-color: transparent;
    border-radius: 0.375rem;
    color: currentColor;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.7142857;
    margin: 0;
    overflow-x: auto;
    padding: 0;
  }

  pre code {
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
  }

  pre code:before {
    content: none;
  }

  pre code:after {
    content: none;
  }

  table {
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-bottom: 2em;
    margin-top: 2em;
    table-layout: auto;
    width: 100%;
  }

  table {
    text-align: left;
  }

  thead {
    border-bottom-color: var(--tw-prose-th-borders);
    border-bottom-width: 1px;
  }

  thead th {
    color: var(--tw-prose-headings);
    font-weight: 600;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
    padding-right: 0.5714286em;
    vertical-align: bottom;
  }

  tbody tr {
    border-bottom-color: var(--tw-prose-td-borders);
    border-bottom-width: 1px;
  }

  tbody tr:last-child {
    border-bottom-width: 0;
  }

  tbody td {
    vertical-align: baseline;
  }

  tfoot {
    border-top-color: var(--tw-prose-th-borders);
    border-top-width: 1px;
  }

  tfoot td {
    vertical-align: top;
  }

  font-size: 1rem;
  line-height: 1.75;

  p {
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }

  video {
    margin-bottom: 2em;
    margin-top: 2em;
  }

  figure {
    margin-bottom: 2em;
    margin-top: 2em;
  }

  li {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }

  ol > li {
    padding-left: 0.375em;
  }

  ul > li {
    padding-left: 0.375em;
  }

  ul > li p {
    margin-bottom: 0.75em;
    margin-top: 0.75em;
  }

  ul > li > :first-child {
    margin-top: 1.25em;
  }

  ul > li > :last-child {
    margin-bottom: 1.25em;
  }

  ol > li > :first-child {
    margin-top: 1.25em;
  }

  ol > li > :last-child {
    margin-bottom: 1.25em;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0.75em;
    margin-top: 0.75em;
  }

  hr + * {
    margin-top: 0;
  }

  h2 + * {
    margin-top: 0;
  }

  h3 + * {
    margin-top: 0;
  }

  h4 + * {
    margin-top: 0;
  }

  thead th:first-child {
    padding-left: 0;
  }

  thead th:last-child {
    padding-right: 0;
  }

  tbody td,
  tfoot td {
    padding: 0.5714286em;
  }

  tbody td:first-child,
  tfoot td:first-child {
    padding-left: 0;
  }

  tbody td:last-child,
  tfoot td:last-child {
    padding-right: 0;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  font-size: 1rem;
  line-height: 1.75;
`;
