export const GOOGLE_MAP_API_KEY = 'AIzaSyDQ3VD_ybEGMknTAUxQNaIvVnxbbR45lPU';
export const GOOGLE_MAP_IDS = {
  RASTER: 'c091c4451deb895b',
  EVERYTHING: 'ea49d33c5d24d889',
  GUIDE_GPT: '9d418ae89ec34722',
  JUST_ROAD: '69e7f65fa94f4f7d',
  TOURIST_ATTRACTION: '102d92a1d40822e1',
};

export const GOOGLE_GEOLOC_API_KEY = 'AIzaSyCHuTfFvVGE4c5rL4u3SlueWK7IqLPJF4A';

export const FRANCE_CENTER = {
  lat: 46.9790877,
  lng: 2.1329362,
  zoom: 5.78,
};
