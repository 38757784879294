import {createTheme} from '@shared-web/theme/theme_base';
import {FrontendTheme} from '@shared-web/theme/theme_model';

export const theme: FrontendTheme = createTheme(
  {accentColor: '#0049be'},
  {
    radio: {
      labelPaddingLeft: 0,
      labelPaddingBottom: 0,
      labelPaddingRight: 0,
      labelPaddingTop: 0,
      titleMarginBottom: 0,
    },
  }
);
