import {GoogleMapProps} from '@react-google-maps/api';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {styled} from 'styled-components';

import {PlaceItem} from '@shared/api/app_api';
import {FRANCE_CENTER, GOOGLE_GEOLOC_API_KEY, GOOGLE_MAP_IDS} from '@shared/lib/constants';
import {asMap, asNumber, asString} from '@shared/lib/type_utils';

import {Button} from '@shared-web/components/core/button';
import {LoadingIndicator} from '@shared-web/components/core/loading_indicator';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {magicIcon} from '@shared-web/components/icons/magic_icon';
import {mapsIcon} from '@shared-web/components/icons/maps_icon';
import {notifyError} from '@shared-web/lib/notification';
import {useGeoloc} from '@shared-web/lib/use_geoloc';
import {useMemoRef} from '@shared-web/lib/use_memo_ref';
import {useStateRef} from '@shared-web/lib/use_state_ref';
import {useTheme} from '@shared-web/theme/theme_context';

import {AdvancedMarker} from '@src/components/advanced_marker_element';
import {GoogleMaps} from '@src/components/google_maps';
import {ItineraryModal} from '@src/components/itinerary_modal';
import {PlaceAutocomplete} from '@src/components/place_autocomplete';
import {PlaceLoader} from '@src/components/place_loader';
import {fetchPlaceDetails, usePlaceDetails} from '@src/lib/stores';

export const HomePage: React.FC = () => {
  const {
    button: {backgroundActive, textColorActive},
  } = useTheme();
  const {geoloc, refreshGeoloc, isRefreshingGeoloc} = useGeoloc();
  const [ipGeoloc, setIpGeoloc] = useState<{
    accuracy: number;
    location: {lat: number; lng: number};
  }>();
  const [, setBounds, boundsRef] = useStateRef<google.maps.LatLngBoundsLiteral | undefined>();

  // Handle clicking on a place on the map and showing the modal
  const [selectedPlaceId, setSelectedPlaceId] = useState<string>();
  const handleCloseSelectedPlace = useCallback(() => setSelectedPlaceId(undefined), []);
  const handleGoogleMapsClick = useCallback<Exclude<GoogleMapProps['onClick'], undefined>>(evt => {
    if ('placeId' in evt) {
      const id = asString(evt.placeId);
      if (id !== undefined) {
        setSelectedPlaceId(id);
      }
    }
    evt.stop();
  }, []);

  // Handle clicking on the itinary button and showing the modal
  const [itineraryModal, setItineraryModal] = useState<{lat: number; lng: number}>();
  const showItineraryModal = useCallback(() => {
    if (!boundsRef.current) {
      return;
    }
    const {east, west, north, south} = boundsRef.current;
    setItineraryModal({lat: (north + south) / 2, lng: (east + west) / 2});
  }, [boundsRef]);
  const hideItineraryModal = useCallback(() => setItineraryModal(undefined), []);

  // Track changes to the address input
  const [autocompleteItem, setAutocompleteItem] = useState<PlaceItem>();
  const autocompleteItemInfo = usePlaceDetails(autocompleteItem?.placeId);
  const autocompleteItemChange = useCallback((item?: PlaceItem) => setAutocompleteItem(item), []);
  useEffect(() => fetchPlaceDetails(autocompleteItem?.placeId), [autocompleteItem]);

  // Handle clicking on the geoloc button in the address bar
  const handleGeolocClick = useCallback(() => {
    setAutocompleteItem(undefined);
    refreshGeoloc();
  }, [refreshGeoloc]);

  // Trigger a geolocation base on the IP address
  useEffect(() => {
    fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_GEOLOC_API_KEY}`, {
      method: 'POST',
    })
      .then(async res => await res.json())
      .then(res => {
        const json = asMap(res, {});
        const accuracy = asNumber(json['accuracy']);
        const locationMap = asMap(json['location'], {});
        const lat = asNumber(locationMap['lat']);
        const lng = asNumber(locationMap['lng']);
        if (accuracy === undefined || lat === undefined || lng === undefined) {
          return;
        }
        setIpGeoloc({accuracy, location: {lat, lng}});
      })
      .catch((err: unknown) => {
        notifyError(err, {message: 'Échec de la localisation par IP'});
      });
  }, []);

  // Compute the map position
  const [pos, posRef] = useMemoRef(() => {
    if (autocompleteItemInfo) {
      const {latitude, longitude} = autocompleteItemInfo.location;
      return {lat: latitude, lng: longitude, zoom: 16};
    }
    if (geoloc.type === 'success') {
      const {latitude, longitude} = geoloc.loc;
      return {lat: latitude, lng: longitude, zoom: 16};
    }
    if (ipGeoloc !== undefined) {
      const {lat, lng} = ipGeoloc.location;
      return {lat, lng, zoom: 15};
    }
    return FRANCE_CENTER;
  }, [autocompleteItemInfo, geoloc, ipGeoloc]);

  const isGeoloc = useMemo(() => {
    return (
      geoloc.type === 'success' &&
      geoloc.loc.latitude === pos.lat &&
      geoloc.loc.longitude === pos.lng
    );
  }, [geoloc, pos]);

  return (
    <Wrapper>
      <AutocompleteWrapper>
        <PlaceAutocomplete
          item={autocompleteItem}
          onChange={autocompleteItemChange}
          locationRef={posRef}
          icon={
            <AutocompleteIcon backgroundColor={backgroundActive} color={textColorActive}>
              {isRefreshingGeoloc ||
              (autocompleteItem?.placeId !== undefined &&
                autocompleteItem.placeId !== autocompleteItemInfo?.id) ? (
                <LoadingIndicator size={20} opacity={1} color={textColorActive} />
              ) : (
                <GeolocButton onClick={handleGeolocClick}>
                  <SvgIcon icon={mapsIcon} size={20} color={textColorActive} />
                </GeolocButton>
              )}
            </AutocompleteIcon>
          }
        />
      </AutocompleteWrapper>
      <MapsWrapper>
        <MapWrapper>
          {
            <GoogleMaps
              lat={pos.lat}
              lng={pos.lng}
              zoom={pos.zoom}
              mapId={GOOGLE_MAP_IDS['EVERYTHING']}
              onClick={handleGoogleMapsClick}
              onBoundsChanged={setBounds}
            >
              {isGeoloc ? <AdvancedMarker position={pos}></AdvancedMarker> : <></>}
            </GoogleMaps>
          }
        </MapWrapper>
      </MapsWrapper>
      {itineraryModal ? (
        <></>
      ) : (
        <ItineraryButton onClick={showItineraryModal}>
          <SvgIcon icon={magicIcon} color="#ffffff" size={24} />
        </ItineraryButton>
      )}
      {itineraryModal ? (
        <Modal>
          <ItineraryModal
            lat={itineraryModal.lat}
            lng={itineraryModal.lng}
            onClose={hideItineraryModal}
          />
        </Modal>
      ) : (
        <></>
      )}
      {selectedPlaceId !== undefined ? (
        <Modal>
          <PlaceLoader onClose={handleCloseSelectedPlace} placeId={selectedPlaceId} />
        </Modal>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
HomePage.displayName = 'HomePage';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;

const MapsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Modal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  overflow-y: auto;
  z-index: 20;
`;

const ItineraryButton = styled(Button)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AutocompleteWrapper = styled.div`
  position: fixed;
  top: calc(env(safe-area-inset-top) + 16px);
  left: 16px;
  right: 16px;
  z-index: 10;
`;

const AutocompleteIcon = styled.div<{backgroundColor?: string; color?: string}>`
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.backgroundColor ?? 'transparent'};
  color: ${p => p.backgroundColor ?? 'inherit'};
`;

const GeolocButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
