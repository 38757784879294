import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {PlaceItem} from '@shared/api/app_api';

import {Autocomplete} from '@shared-web/components/core/autocomplete';
import {ReadOnlyRefObject} from '@shared-web/lib/react';

import {apiCall} from '@src/lib/api';

interface PlaceAutocompleteProps {
  item?: PlaceItem;
  onChange?: (item?: PlaceItem) => void;
  icon?: JSX.Element;
  locationRef: ReadOnlyRefObject<{
    lat: number;
    lng: number;
    zoom: number;
  }>;
}

const LOCATION_BIAS_RADIUS = 100000; // 100km

export const PlaceAutocomplete: FC<PlaceAutocompleteProps> = props => {
  const {item, onChange, icon, locationRef} = props;

  const autocompleteQuery = useCallback(
    async (query: string) => {
      const {lat, lng} = locationRef.current;
      const {res} = await apiCall('POST /place-autocomplete', {
        query,
        locationBias: {lat, lng, radius: LOCATION_BIAS_RADIUS},
      });
      return res;
    },
    [locationRef]
  );

  const itemToInputString = useCallback((item: PlaceItem) => item.label, []);

  const renderItem = useCallback(
    (item: PlaceItem, highlighted: boolean) => (
      <Item highlighted={highlighted}>
        <Title>{item.mainText}</Title>
        <SubTitle>{item.secondaryText}</SubTitle>
      </Item>
    ),
    []
  );

  const itemToKey = useCallback((item: PlaceItem) => item.placeId, []);

  return (
    <Autocomplete
      item={item}
      itemToInputString={itemToInputString}
      syncState={onChange}
      lookupItem={autocompleteQuery}
      itemElement={renderItem}
      itemToKey={itemToKey}
      autoFocus
      overrides={{
        textColor: '#000',
        focusTextColor: '#000',
        backgroundColor: '#ffffffe0',
        backgroundColorFocus: '#ffffff',
      }}
      inputProps={{icon}}
    />
  );
};

PlaceAutocomplete.displayName = 'PlaceAutocomplete';

const Item = styled.div<{highlighted?: boolean}>`
  display: flex;
  flex-direction: column;
  background-color: ${p => (p.highlighted ? '#eeeeee' : '#ffffff')};
  padding: 16px;
`;

const Title = styled.div`
  font-size: 18px;
  color: #333;
`;

const SubTitle = styled.div`
  font-size: 14px;
  color: #777;
`;
