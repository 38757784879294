import {GoogleMap, GoogleMapProps as OriginalGoogleMapProps} from '@react-google-maps/api';
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {GOOGLE_MAP_API_KEY} from '@shared/lib/constants';

import {ErrorBoundary} from '@shared-web/components/core/error_boundary';
import {usePrevious} from '@shared-web/lib/use_previous';

interface GoogleMapsProps {
  lat: number;
  lng: number;
  zoom?: number;
  children?: ReactNode;
  mapId?: string;
  onClick?: OriginalGoogleMapProps['onClick'];
  onBoundsChanged?: (bounds: google.maps.LatLngBoundsLiteral) => void;
  onSizeChanged?: (size: {width: number; height: number}) => void;
}

const MAP_INITIAL_ZOOM = 11;

interface GoogleMapsWindow {
  google?: {
    maps?: {
      importLibrary?: (f: string, ...n: unknown[]) => Promise<void>;
    } & Record<string, unknown>;
  };
  initMap?: () => void;
}

let loadingPromise: Promise<void> | undefined;

async function loadGoogleMap(): Promise<void> {
  if (loadingPromise !== undefined) {
    return await loadingPromise;
  }

  loadingPromise = new Promise<void>((resolve, reject) => {
    // Initialize global variables
    const w = window as unknown as GoogleMapsWindow;
    if (!w.google) {
      w.google = {};
    }
    if (!w.google.maps) {
      w.google.maps = {};
    }

    // Register the callback
    w['initMap'] = () => {
      google.maps
        .importLibrary('marker')
        .then(() => {
          resolve();
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
        .catch(reject);
    };

    // Create the URL
    const urlParams = new URLSearchParams();
    urlParams.set('key', GOOGLE_MAP_API_KEY);
    urlParams.set('callback', `initMap`);
    urlParams.set('v', `weekly`);
    const url = `https://maps.googleapis.com/maps/api/js?${urlParams.toString()}`;

    // Create the script tag
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.onerror = (): void => {
      reject(new Error(`Google Maps could not load.`));
    };
    document.head.append(scriptTag);
  });
  return await loadingPromise;
}

function useGoogleMap(): {isLoaded: boolean} {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    loadGoogleMap()
      .then(() => {
        setIsLoaded(true);
      })
      .catch((err: unknown) => console.error(err));
  }, []);
  return {isLoaded};
}

export const GoogleMaps: FC<GoogleMapsProps> = props => {
  const {
    lat,
    lng,
    mapId,
    zoom = MAP_INITIAL_ZOOM,
    onClick,
    onBoundsChanged,
    onSizeChanged,
    children,
  } = props;
  const {isLoaded} = useGoogleMap();
  const [map, setMap] = useState<google.maps.Map>();
  const bounds = useRef<google.maps.LatLngBoundsLiteral>();
  const size = useRef<{width: number; height: number}>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  // These variables stores the actual attributes we want to provide to the map.
  // They are updated only when we change the mapId/lat/lng props changes.
  // We don't want to update them unless the mapId/lat/lng props changes since they are tracked
  // internally by the google maps component.
  // We only care to update them when we change the mapId/lat/lng props since we need to recreate the map
  // and therefore need to provide the initial values that matches the one of the previous map.
  const [centerAttr, setCenterAttr] = useState({lat, lng});
  const [zoomAttr, setZoomAttr] = useState(zoom);
  const [mapIdAttr, setMapIdAttr] = useState(mapId);

  useEffect(() => setCenterAttr({lat, lng}), [lat, lng]);
  useEffect(() => setZoomAttr(zoom), [zoom]);

  // Handle the logic when the map id changes.
  // We use a `reload` variable to remove the initial map and re-render again
  // the map with the new attributes.
  const prevMapId = usePrevious(mapId);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (prevMapId !== undefined && prevMapId !== mapId && map) {
      setReload(true);
    }
  }, [map, mapId, prevMapId]);

  useEffect(() => {
    if (reload) {
      setReload(false);
      if (map) {
        const newZoom = map.getZoom();
        if (newZoom !== undefined) {
          setZoomAttr(newZoom);
        }
      }
      if (bounds.current) {
        const {east, west, north, south} = bounds.current;
        setCenterAttr({lat: (north + south) / 2, lng: (east + west) / 2});
      }
      setMapIdAttr(mapId);
    }
  }, [map, mapId, reload]);

  //

  const handleBoundsChanged = useCallback(() => {
    if (!map || !onBoundsChanged) {
      return;
    }
    const newBounds = map.getBounds()?.toJSON();
    if (!newBounds) {
      return;
    }
    // Ensure bounds changed
    if (bounds.current) {
      const {north, east, south, west} = bounds.current;
      if (
        north === newBounds.north &&
        east === newBounds.east &&
        south === newBounds.south &&
        west === newBounds.west
      ) {
        return;
      }
    }
    bounds.current = newBounds;
    onBoundsChanged(newBounds);
  }, [map, onBoundsChanged]);

  useEffect(() => {
    if (!onSizeChanged) {
      return;
    }
    const handleResize = (): void => {
      const rect = wrapperRef.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }
      // Ensure size changed
      if (size.current) {
        const {width, height} = size.current;
        if (width === rect.width && height === rect.height) {
          return;
        }
      }
      const {width, height} = rect;
      size.current = {width, height};
      onSizeChanged({width, height});
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [onSizeChanged]);

  const mapContainerStyle = useMemo(
    () => ({
      width: '100%',
      height: '100%',
    }),
    []
  );
  const options = useMemo(
    () => ({
      mapId: mapIdAttr,
      disableDefaultUI: true,
      isFractionalZoomEnabled: true,
      gestureHandling: 'greedy',
    }),
    [mapIdAttr]
  );

  return (
    <ErrorBoundary>
      <Wrapper ref={wrapperRef}>
        {isLoaded && !reload ? (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={centerAttr}
            clickableIcons
            zoom={zoomAttr}
            onClick={onClick}
            onIdle={handleBoundsChanged}
            onLoad={setMap}
            options={options}
          >
            {children}
          </GoogleMap>
        ) : (
          <></>
        )}
      </Wrapper>
    </ErrorBoundary>
  );
};
GoogleMaps.displayName = 'GoogleMaps';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .gmnoprint,
  .gm-style-cc,
  .gm-style-iw-c > button {
    display: none;
  }
  .gm-style iframe + div {
    border: none !important;
  }
  // Remove Google logo
  div > a > div > img {
    display: none;
  }
`;
