import {CSSProperties} from 'react';

import {Place} from '@shared/api/app_api';
import {GOOGLE_MAP_API_KEY} from '@shared/lib/constants';

export function placePhotoBackground(
  photo: Place['photos'][0] | undefined,
  targetWidth: number
): CSSProperties {
  if (!photo) {
    return {};
  }
  const {name, width, height} = photo;
  const sizeParam = width > height ? 'maxHeightPx' : 'maxWidthPx';
  const url = `https://places.googleapis.com/v1/${name}/media?key=${GOOGLE_MAP_API_KEY}&${sizeParam}=${2 * targetWidth}`;
  return {backgroundImage: `url(${url})`};
}
