import {FC, useEffect, useMemo, useState} from 'react';
import {styled} from 'styled-components';

import {UnthemedButton} from '@shared-web/components/core/button';
import {LoadingIndicator} from '@shared-web/components/core/loading_indicator';
import {Radios} from '@shared-web/components/core/radios';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {closeIcon} from '@shared-web/components/icons/close_icon';

import {ChatGptMarkdown} from '@src/components/chat_gpt_markdown';
import {generateItineraryPrompt, ItineraryRadius} from '@src/lib/chat_gpt_prompts';
import {
  fetchChatGpt,
  fetchGeocodingAddress,
  useChatGpt,
  useGeocodingAddress,
} from '@src/lib/stores';

interface ItineraryModalProps {
  lat: number;
  lng: number;
  onClose?: () => void;
}

export const ItineraryModal: FC<ItineraryModalProps> = ({lat, lng, onClose}) => {
  // Address loading
  const addressResult = useGeocodingAddress(`${lat};${lng}`);
  useEffect(() => fetchGeocodingAddress({lat, lng}), [lat, lng]);

  // Chat GPT loading
  const [radius, setRadius] = useState<ItineraryRadius>(ItineraryRadius.Quartier);
  const prompt = useMemo(() => {
    if (!addressResult) {
      return;
    }
    const {country, city, address} = addressResult;
    const prompt = generateItineraryPrompt({country, city, address, radius});
    return prompt;
  }, [addressResult, radius]);
  const chatGpt = useChatGpt(prompt);
  useEffect(() => fetchChatGpt(prompt), [prompt]);

  return (
    <Wrapper>
      <CloseIcon onClick={onClose}>
        <SvgIcon icon={closeIcon} size={24} color="#555" />
      </CloseIcon>
      <Content>
        <div>
          <Radios<ItineraryRadius>
            label="Zone de recherche"
            value={radius}
            values={[
              {value: ItineraryRadius.Quartier, label: 'Quartier'},
              {value: ItineraryRadius.Ville, label: 'Ville'},
              {value: ItineraryRadius.Pays, label: 'Pays'},
            ]}
            syncState={setRadius}
          />
        </div>
        <ChatGpt>
          {addressResult === undefined ? (
            <Loading>
              <LoadingIndicator />
              <div>Chargement de l'adresse...</div>
            </Loading>
          ) : chatGpt === undefined ? (
            <Loading>
              <LoadingIndicator />
              <div>Chargement des points d'intérêts...</div>
            </Loading>
          ) : (
            <ChatGptMarkdown message={chatGpt} />
          )}
        </ChatGpt>
      </Content>
    </Wrapper>
  );
};

ItineraryModal.displayName = 'ItineraryModal';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: calc(env(safe-area-inset-top) + 16px) 16px env(safe-area-inset-bottom) 16px;
  gap: 16px;
`;

const ChatGpt = styled.div`
  position: relative;
  flex-grow: 1;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

const CloseIcon = styled(UnthemedButton)`
  display: flex;
  position: fixed;
  top: env(safe-area-inset-top);
  right: 0;
  padding: 16px;
  background: none;
  z-index: 20;
`;
